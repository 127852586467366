import { formatBytes, getImageUrl } from "@/commons/utils";

interface IFile {
    id?: any;
    fileName?: string;
    file?: any;
    type?: FileType;
    size?: number;
    url?: string;
    lastModified?: string;
    modifiedBy?: string;
}
class File implements IFile{
    id?: any;
    fileName?: string;
    file?: any;
    type?: FileType;
    size?: number;
    lastModified?: string;
    modifiedBy?: string;
    url?: string;
    constructor(obj: any) {
        if(obj) {
            this.id = obj.id
            this.fileName = obj.fileName;
            this.file = obj.file;
            this.type = this.format(obj.type?.toLowerCase());
            this.size = obj.size;
            this.url = obj.url;
            this.lastModified = obj.lastModified;
            this.modifiedBy = obj.modifiedBy;
        }
    }
    format(_type: any): FileType {
        if(Object.values(FileType).includes(_type as FileType)) {
            return _type;
        } else {
            return FileType.UNKNOW;
        }
    }
    get sizeFormat() {
        return formatBytes(this.size ?? 0);
    }
    get fileUrl(): string {
        return getImageUrl(this);
    }

    download() {
        window.open(this.url, '_blank');
    }
}

enum FileType {
    PPTX='pptx', DOCX='docx', XLSX='xlsx', IMAGE='image', PDF='pdf', SOUND='sound', TXT='txt', VIDEO='video', UNKNOW='unknow'
}

export { IFile, FileType, File };