import { IToast } from "@/models/toast";
import { defineStore } from "pinia";
import { reactive } from "vue";

export const useToastStore = defineStore("toast", () => {
    const toasts = reactive<Array<IToast>>([]);
    function push(toast: IToast) {
        toasts.push(toast);
    }
    function remove(toast: IToast) {
        const index = toasts.findIndex(item => item.key == toast.key);
        toasts.splice(index, 1);
    }
    return {
        toasts,
        push,
        remove
    }
})