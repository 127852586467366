import { http } from "@/commons/http-common";
import { History, IHistory } from "@/models/history";
import { IPageAble } from "@/models/page";

class HistoryService {
    list(page: number, size: number): Promise<IPageAble<IHistory>>  {
        return http.post('/history/list', {page: page, size: size},
            {headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then((response: any) => {
            const data = response.data;
            const pageAble:IPageAble<IHistory> = {
                list: data.history.map((history: any) => new History(history)),
                total: data.total,
                page: page,
                itemPerPage: size
            }
            return pageAble;
        })
        .catch((error: any) => {
            throw error;
        });
    }
    delete(id: any) {
        return http.post('/history/delete', {id: id},
            {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
    }
}

export default new HistoryService();