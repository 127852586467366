import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import { createPinia } from 'pinia';
import InlineSvg from 'vue-inline-svg';
import i18nPlugin from './plugins/i18n';
import DxIcon from './components/DxIcon.vue';
import GlobalPlugin from './plugins/global';

const pinia = createPinia();

createApp(App)
.component('inline-svg', InlineSvg)
.component('dx-icon', DxIcon)
.use(router)
.use(i18nPlugin)
.use(GlobalPlugin)
.use(pinia)
.mount('#app');