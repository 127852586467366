import IntroductionView from '@/views/IntroductionView.vue'
import LoginView from '@/views/LoginView.vue'
import FileSearchView from '@/views/pages/FileSearchView.vue'
import FileTranslationView from '@/views/pages/FileTranslationView.vue'
import HistoryView from '@/views/pages/HistoryView.vue'
import ScanToTextView from '@/views/pages/ScanToTextView.vue'
import TextTranslationView from '@/views/pages/TextTranslationView.vue'
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import( '../views/HomeView.vue'),
    children: [
      {
        path: '',
        name: 'main',
        redirect: { name: 'file-search'}
      },
      {
        path: 'file-search',
        name: 'file-search',
        component: FileSearchView
      },
      {
        path: 'file-translation',
        name: 'file-translation',
        component: FileTranslationView
      }, 
      {
        path: "text-translation",
        name: "text-translation",
        component: TextTranslationView
      },
      {
        path: 'scan-to-text',
        name: 'scan-to-text',
        component: ScanToTextView
      },
      {
        path: 'history',
        name: 'history',
        component: HistoryView
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/introduction',
    name: 'introduction',
    component: IntroductionView
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// const useAccount = useAccountStore();
// router.beforeEach((to, from, next) => {
//   userService.login()
//   .then(user => {
//     if(user) {
//       useAccount.account = user;
//       console.log(to);
//       if(router.currentRoute.value.name == 'login') {
//         router.push({name: 'main'});
//       }
//     } else {
//       router.push({name: 'login'});
//     }
//   })
//   next();
// });
export default router
