<template>
    <div class="page-container">
        <div class="page-container__header">
            <DxLanguagesOption v-model="languageOption" class="center-area" @on-select="result = ''; handleInput()"/>
            <TopBar :class="'topbar'" >
                <template v-slot:pageTitle>
                    <div class="dx-page-title">
                        <img src="@/assets/imgs/sidebar/text-translation.svg"/>
                        {{ $t('textTranslationPage.title') }}
                    </div>
                </template>
            </TopBar>
        </div>
        <div class="page-container__body">
            <div class="dx-card translate-data-card">
                <div class="translate-data-card__header--border" v-if="result">
                    <div class="actions" v-if="result">
                        <button class="action new-action dx-button dx-button-outline" @click="newTranslation">
                            <inline-svg :src="require('@/assets/imgs/icons/new-file-translation.svg')"/>
                            <div class="label">
                                {{ $t('textTranslationPage.newTranslation') }}
                            </div>
                        </button>
                    </div>
                </div>
                <div class="translate-data-card__body">
                    <textarea ref="textRef" @input="onInput" maxlength="5000" v-model="text" autocorrect="off" autocapitalize="off" spellcheck="false"></textarea>
                </div>
                <div class="translate-data-card__footer only-desktop">
                    <div class="text-count">{{text.length || 0}}/5000 {{ $t('textTranslationPage.words') }}</div>
                </div>
            </div>
            <div class="dx-card translate-data-card--result" :class="{'has-data': result, 'loading': !result && loading}">
                <DxSpinner class="spinner"/>
                <Transition>
                    <div class="translate-data-card__header" :class="{'visibility-hidden': !result}">
                        <div class="actions">
                            <div class="action" @click="download">
                                <inline-svg :src="require('@/assets/imgs/icons/download.svg')"/>
                                <div class="label only-desktop">
                                    {{ $t('textTranslationPage.download') }}
                                </div>
                            </div>
                            <div class="action" @click="copy">
                                <inline-svg :src="require('@/assets/imgs/icons/copy.svg')"/>
                                <div class="label only-desktop">
                                    {{ $t('textTranslationPage.copy') }}
                                </div>
                            </div>
                            <button class="action new-action dx-button dx-button-outline" @click="newTranslation">
                                <inline-svg :src="require('@/assets/imgs/icons/new-file-translation.svg')"/>
                                <div class="label">
                                    {{ $t('textTranslationPage.newTranslation') }}
                                </div>
                            </button>
                        </div>
                    </div>
                </Transition>
                <div class="translate-data-card__body">
                    <div class="illustration-image" v-if="!result">
                        <span v-html="$t('textTranslationPage.resultPlaceText')"></span>
                        <img class="illustration-image__img" src="@/assets/imgs/text-translation/illustration.svg"/>
                    </div>
                    <div class="translate-data-card__body__result" :class="{'writing': result && (writing || loading)}" v-html="resultFormat">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { copyToClipboad, debounce, downloadTxtFile } from '@/commons/utils';
import DxLanguagesOption from '@/components/DxLanguagesOption.vue';
import DxSpinner from '@/components/DxSpinner.vue';
import TopBar from '@/components/TopBar.vue';
import { useToast } from '@/composables/useToast';
import llmService from '@/services/llm.service';
import { useSearchStore } from '@/store/search';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const searchStore = useSearchStore();
const languageOption = ref();

const text = ref('');
const textRef = ref();

const result = ref('');
const resultFormat = computed(() => {
    return result.value?.trim()?.replace(/\\n/g, '\n')
                               ?.replace(/^\[\s*"|"\s*]$/g, '') ?? '';
});
const loading = ref(false);
const writing = ref(false);

let reader: ReadableStreamDefaultReader; 
let abortControllers: AbortController[] = [];


onMounted(() => {
    if(searchStore.textTranslateData) {
        languageOption.value = searchStore.textTranslateData.languages;
        text.value = searchStore.textTranslateData.text ?? '';
        result.value = searchStore.textTranslateData.result ?? '';
    }
    const mobileView = window.matchMedia("(max-width: 786px)")
    if(!mobileView.matches) {
        textRef.value?.focus();
    }
})
const onInput  = debounce(handleInput, 300);

function handleInput() {
    if(text.value.trim() != "") {
        loading.value = true;
        if(abortControllers.length > 1) {
            abortControllers.slice(1).forEach(controller => controller?.abort("Cancel request"));
            abortControllers = [abortControllers[0]];
        }    
        const fetch = llmService.translate({
            content: text.value,
            languages: languageOption.value,
        });
        abortControllers.push(fetch.controller);
        fetch.promise.then((data) => {
            const index = abortControllers.indexOf(fetch.controller);
            abortControllers.forEach((controller, i) => {
                if(i < index) {
                    controller?.abort("Cancel request")
                }
            });
            writeResult(data);
        })
        .catch((err) => {
            if (err.name == 'AbortError' || err == "Cancel request") {
                return;
            }
            useToast({message: err.message, type: 'error'});
        })
        .finally(() => {
            abortControllers = abortControllers.filter(item => item !== fetch.controller);
            if(abortControllers.length == 0) {
                loading.value = false;
            }
        });
    } else {
        abortControllers.forEach(controller => controller?.abort("Cancel request"));
        abortControllers = [];
        loading.value = false;
        writing.value = false;
        reader?.cancel();
        setTimeout(() => {
            result.value = '';
        }, 0);
    }
}

async function writeResult(stream: ReadableStream) {
    reader?.cancel();
    if(text.value.trim() == "") return;
    reader = stream.getReader();
    let resultText = '';
    const decoder = new TextDecoder();
    let { done, value } = await reader.read();
    let decoded = '';
    while (!done || decoded !== '') {
        try {
            writing.value = true;
            decoded += decoder.decode(value);
            if (!done && !decoded.includes('\n')) {
                ({ done, value } = await reader.read());
                continue;
            }

            const lines = decoded.split('\n');
            lines.forEach(line => {
                if(line != "") {
                    const content = JSON.parse(line);
                    if (content?.content !== undefined) {
                        resultText += content.content;
                    }
                }
            });
            if(!result.value.startsWith(resultText)) {
                result.value = resultText;
            }
        } catch {
            useToast({type: "error", message: t("errorMessage.readingStreamData")});
        }
        decoded = '';
        ({ done, value } = await reader.read());
    }
    writing.value = false;
    result.value = resultText;
    searchStore.textTranslateData = {};
    searchStore.textTranslateData.languages = languageOption.value;
    searchStore.textTranslateData.text = text.value;
    searchStore.textTranslateData.result = result.value;
}


function download() {
    downloadTxtFile(result.value, "translation.txt");
}
function copy() {
    copyToClipboad(result.value).then(() => {
        useToast({type: 'success', message: "Copied!", deleyTime: 1})
    });
}
function newTranslation() {
    reader?.cancel();
    text.value = '';
    result.value = '';
    writing.value = false;
    searchStore.textTranslateData = null;
    textRef.value?.focus();
}
onUnmounted(() => {
    reader?.cancel();
    abortControllers.forEach(controller => controller?.abort("Cancel request"));
    abortControllers = [];
})
</script>
<style lang="scss" scoped>
.page-container__header {
    display: flex;
    position: relative;
    flex-wrap: wrap-reverse;
    .center-area {
        z-index: 1;
        position: absolute;
        left: 50%;
        transform: translate(-50%);
    }
    .topbar {
        margin-left: auto;
    }

    @media only screen and (max-width: 1200px) {
        > * {
            width: 100%;
        }
        .center-area {
            position: static;
            left: 0;
            transform: initial;
        }
        .topbar {
            margin-left: auto;
            width: fit-content;
            padding-bottom: 15px;
        }
    }
}

textarea {
    flex: 1;
    width: 100%;
    background-color: transparent;
    font-family: inherit;
    // padding: 50px;
    border: none;
    outline: none;
    resize: none;
    font-size: inherit;
    color: $color1;
}
.text-count {
    font-size: 11px;
    width: 100%;
    text-align: right;
}

.page-container__body {
    flex: 1;
    margin-top: 20px;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    gap: 23px;
    overflow: auto;
    .translate-data-card {
        background-color: rgba($color: white, $alpha: 0.6);
        border: none;
        position: relative;
        display: flex;
        flex-direction: column;
        .spinner {
            display: none;
            position: absolute;
            top: 50%;
            left: 50%;
            translate: -50% -50%;
        }
        &.loading {
            >*:not(.spinner) {
                opacity: 0;
            }
            .spinner {
                display: block;
            }
        }
        &--result {
            @extend .translate-data-card;
            .translate-data-card__header {
                display: flex;
            }
            .translate-data-card__body {
                padding-left: 80px;
                padding-right: 80px; 
            }
        }
        &__header {
            font-size: 14px;
            margin: 0 5px;
            padding: 0 25px;
            height: 70px;
            line-height: 70px;
            display: none;
            &--border {
                @extend .translate-data-card__header;
                border-bottom: 1px solid rgba($color1, 0.3);
            }
        }
        
        &__body {
            flex: 1;
            min-height: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            overflow: auto;
            padding: 40px;
            font-size: 18px;
            &__result {
                font-size: inherit;
                color: $color1;
                height: 100%;
                width: 100%;
                line-height: 25px;
                white-space: break-spaces;
                word-break: break-word;
                &.writing {
                    &::after {
                        content: "...";
                        animation: writing 1s infinite;
                        font-size: 20px;
                        line-height: 0;
                        max-height: 0;
                        display: inline-block;
                    }
                }
                @keyframes writing {
                    0% {
                        content: "."
                    } 
                    25% {
                        content: ".."
                    }
                    50% {
                        content: "..."
                    }
                    100% {
                        content: "."
                    }
                }
            }
        }
        &__footer {
            padding: 25px;
            .dx-button {
                border: 1px solid $color4;
                color: $color4;
                margin-left: auto;
                height: 36px;
                padding: 0 40px;
            }
        }
    }
}

.actions {
    display: flex;
    align-items: center;
    font-size: 12px;
    width: 100%;
    .action {
        &:not(:first-child) {
            margin-left: 28px;
        }
        display: flex;
        align-items: center;
        height: 36px;
        cursor: pointer;
        border-radius: 8px;
        overflow: hidden;
        .label {
            flex: 1;
            overflow: hidden;
            margin-left: 0.5em;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        &:not(.new-action):hover {
            color: rgba($color1, 0.6);
        }
        &.new-action {
            margin-left: auto;
        }
    }
}

.illustration-image {
    position: absolute;
    inset: min(70px, 10%);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: rgba($color1, 0.4);
    gap: 40px;
    &::before {
        content: "";
        display: block;
        flex: 1;
    }
    span {
        width: 15em;
        text-align: center;
        font-size: 16px;
        max-width: 80%;
    }
    &__img {
        width: 180px;
        max-width: 100%;
        object-fit: contain;
        object-position: top;
        flex: 1;
    }
}
@media only screen and (max-width: 768px), (max-aspect-ratio: 1/1) {
    .page-container {
        &__header {
            gap: 0;
            .dx-lang-options {
                padding: 15px 20px;
                :deep(.language) {
                    flex: 1;
                    min-width: 0;
                    .dx-select {
                        width: 100%;
                        max-width: 100%;
                        min-width: auto;
                        .dx-select-input {
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
        &__body {
            grid-auto-flow: row;
            grid-template-columns: 1fr;
            grid-template-rows: repeat(auto-fit, minmax(0, 1fr));
            margin-top: 0;
            min-height: 0;
            .translate-data-card {
                border-radius: 0px;
                &--result {
                    display: none;
                    &.has-data {
                        display: flex;
                    }
                    .actions {
                        justify-content: flex-end;
                        .action.new-action {
                            display: none;
                        }
                    }
                }
                &__header {
                    padding: 0 20px;
                    margin: 0;
                    display: flex;
                    height: 55px;
                }
                &__body {
                    padding: 0 40px !important;;
                    font-size: 13px;
                }
            }
        }
    }
    textarea {
        padding: 15px 0;
    }
    .actions {
        .action.new-action {
            height: 32px;
        }
    }
}
</style>