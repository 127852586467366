import { onMounted, onUnmounted, watchEffect } from "vue";

export function useInfiniteScroll(target: any, callback: any) {

    watchEffect(() => {
        target.value?.addEventListener("scroll", handleScroll)
    })

    onUnmounted(() => {
        target.value?.addEventListener("scroll", handleScroll)
    })

    function handleScroll(e: Event) {
        const element = target.value
        if(!element) return; 
        const { scrollTop, scrollHeight, clientHeight } = element;

        if (scrollTop + clientHeight >= scrollHeight - 5) {
            callback();
        }
    }
}