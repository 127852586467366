import { HEADERS, http, useMock } from "@/commons/http-common";
import { File, IFile } from "@/models/file";
import { IPageAble } from "@/models/page";

class DocService {
    uploadDoc(file: IFile) {
        const form = new FormData();
        form.append("file", file.file);
        return http.post('/doc/upload', form, {
            headers: HEADERS.form,
        })
        .then(res => { return res.data })
        .catch(err => { throw err });
    }

    getDocs(page: number, size: number): Promise<IPageAble<IFile>> {
        return http.post('/doc/list', {page: page, size: size},
            {headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then((response: any) => {
            const data = response.data;
            const pageAble:IPageAble<IFile> = {
                list: data.documents.map((doc: any) => new File({
                    id: doc.id,
                    fileName: doc.name,
                    size: doc.size,
                    type: doc.format,
                    file: doc.file,
                    lastModified: doc.modified,
                    modifiedBy: `${doc.owner?.first} ${doc.owner?.last}`,
                    url: `${useMock ? '' : process.env.VUE_APP_API_URL}${doc.url}`
                })),
                total: data.total,
                page: page,
                itemPerPage: size
            }
            return pageAble;
        })
        .catch((error: any) => {
            throw error;
        });
    }

    deleteDoc(id: any) {
        return http.post('/doc/delete', {id: id},
            {headers: {'Content-Type': 'application/x-www-form-urlencoded'}});
    }
    

}
 export default new DocService();