
import { Plugin } from 'vue';
import { createI18n } from 'vue-i18n';

import en from '../i18n/en.json';
import ja from '../i18n/ja.json';
import moment from 'moment';

const defaultLocale = 'en'
const currentLocale = localStorage.getItem('dx-currentLang') || defaultLocale;

const languages = { en: en, ja: ja }
const i18nPlugin: Plugin = {
    install(app) {
        const messages = languages;
        moment.locale(currentLocale);
        const i18n = createI18n({
            legacy: false,
            locale: currentLocale,
            fallbackLocale: defaultLocale,
            silentTranslationWarn: true,
            warnHtmlMessage: false,
            missingWarn: false, 
            fallbackWarn: false,
            messages,
            
        })
  
      app.use(i18n);
    }
};

export default i18nPlugin;