import { IAccount, IPreference } from '@/models/account'
import { AiliaRealtimeService } from '@/services/ailia-realtime.service';
import { defineStore } from 'pinia'
import { inject, ref, watch } from 'vue';

export const useAccountStore = defineStore('account', () => {
    const account = ref<IAccount | null>();
    const openSidebar = ref(false);
    const preferences: IPreference = {theme: 'default', language: 'en', autoGenerateHistory: false};
    const ailiaRealtime: AiliaRealtimeService| undefined = inject("ailiaRealtime");

    watch(() => account.value,
    (newValue, oldValue) => {
        if (newValue != null) {
            ailiaRealtime?.connect();
          } else {
            ailiaRealtime?.disconnect();
          }
    })
    return {
        account,
        openSidebar,
        preferences
    }
})