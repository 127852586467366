import ailiaRealtimeService from '@/services/ailia-realtime.service';
import { App } from 'vue';


const GlobalPlugin = {
  install(app: App) {
    // global
    app.provide("ailiaRealtime", ailiaRealtimeService)
  }
};

export default GlobalPlugin;
