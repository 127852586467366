<template>
    <div class="page-container">
        <div class="page-container__header">
            <DxLanguagesOption class="center-area"/>
            <div>
                <TopBar :class="'topbar'" />
            </div>
        </div>
        <div class="page-container__body">
            <div class="dx-card translate-data-card" ref="dropArea">
                <div class="translate-data-card__body dx-card-body">
                    <div class="dx-upload-button dupble-border" v-if="!file" ref="uploadButton">
                        <inline-svg :src="require('@/assets/imgs/icons/upload.svg')"/>
                        <p>Or drag and drop here to translate your files</p>
                    </div>
                    <img v-if="file" class="preview-image" :src="getImageUrl(file.file)"/>
                </div>
            </div>
            <div class="dx-card translate-data-card">
                <div class="translate-data-card__header">
                    <div class="actions" v-if="result">
                        <div class="action" @click="download">
                            <inline-svg :src="require('@/assets/imgs/icons/download.svg')"/>
                            <div class="label">Download</div>
                        </div>
                        <div class="action" @click="copy">
                            <inline-svg :src="require('@/assets/imgs/icons/copy.svg')"/>
                            <div class="label">Copy Text</div>
                        </div>
                        <div class="action new-action dx-buton dx-button-outline" @click="newScan">
                            <inline-svg :src="require('@/assets/imgs/icons/new-file-translation.svg')"/>
                            <div class="label">New Scan to Text</div>
                        </div>
                    </div>
                </div>
                <div class="translate-data-card__body">
                    <div class="translate-data-card__body__result">{{ result }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { copyToClipboad, downloadTxtFile } from '@/commons/utils';
import DxLanguagesOption from '@/components/DxLanguagesOption.vue';
import TopBar from '@/components/TopBar.vue';
import { useUpload } from '@/composables/useUpload';
import { IFile } from '@/models/file';
import docService from '@/services/doc.service';
import llmService from '@/services/llm.service';
import { Ref, ref } from 'vue';

const OPENCV_IMAGE_FORMAT_SUPPORTED = [
  ".bmp", //Windows bitmap
  ".pbm", //Portable image formats
  ".pgm",
  ".ppm",
  ".sr", //Sun raster
  ".ras",
  ".jpeg", //JPEG
  ".jpg",
  ".jpe",
  ".jp2", //JPEG 2000,
  ".tiff", //TIFF files, might not supported by chrome, firefox
  ".tif",
  ".png", //Portable network graphics
  ".svg"
];


const dropArea = ref();
const uploadButton = ref();

const result = ref("");

const file: Ref<IFile | null> = useUpload({
    dropArea: dropArea,
    uploadButton: uploadButton,
    allowedTypes: OPENCV_IMAGE_FORMAT_SUPPORTED,
    callback: async (_file: IFile) => {
        if(!_file) return;
        const { data } = await docService.uploadDoc(_file);
        if(file.value) {
            file.value.id = data.id;
            translate();
        }
    }
}) as Ref<IFile>;

function translate() {
    if(file.value) {
        llmService.translate({documentId: file.value.id, languages: ""})
        .promise
        .then(writeResult)
    }
}
let reader: ReadableStreamDefaultReader; 
async function writeResult(stream: ReadableStream) {
    reader = stream.getReader();
    const decoder = new TextDecoder();
    const loopCondition = true;
    while (loopCondition) {
        const chunk = await reader.read();
        if (chunk.done) {
            break;
        }
        let content;
        try {
            content = JSON.parse(decoder.decode(chunk.value)).content;
        } catch(err) {
            console.log("error", err);
        }
        if (content !== undefined) {
            result.value += content;
        }
    }
}
function getImageUrl(file: File) {
  return URL.createObjectURL(file);
}
function download() {
    downloadTxtFile(result.value, "scan_to_text.txt");
}
function copy() {
    copyToClipboad(result.value);
}
function newScan() {
    reader?.cancel();
    file.value = null;
    result.value = '';
}
</script>
<style lang="scss" scoped>
.page-container__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    >* {
        flex: 1;
        min-width: fit-content;
    }
    &::before {
        content: "";
        flex: 1;
    }
    .topbar {
        margin-left: auto;
    }
}
.page-container__body {
    flex: 1;
    margin-top: 25px;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    gap: 25px;
    .translate-data-card {
        background-color: rgba($color: white, $alpha: 0.4);
        border: none;
        position: relative;
        display: flex;
        flex-direction: column;
        &__header {
            font-size: 14px;
            margin: 0 5px;
            padding: 0 25px;
            height: 70px;
            line-height: 70px;
            display: flex;
            &--border {
                border-bottom: 1px solid rgba($color1, 0.3);
            }
        }
        
        &__body {
            padding: 50px 100px;
            display: flex;
            height: 100%;
            width: 100%;
            .preview-image {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        &__footer {
            padding: 25px;
            .dx-button {
                border: 1px solid $color4;
                color: $color4;
                margin-left: auto;
                height: 36px;
                padding: 0 40px;
            }
        }
        .actions {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-family: $font-family2;
            gap: 1em;
            width: 100%;
            .action {
                display: flex;
                align-items: center;
                height: 36px;
                cursor: pointer;
                border-radius: 8px;
                .label {
                    margin-left: 0.5em;
                }
                &:hover {
                    .label {
                        color: $color4;
                    }
                }
                &.new-action {
                    color: $color4;
                    border: 1px solid rgba($color4, 0.15);
                    background-color: #DBDBFF;
                    margin-left: auto;
                    padding: 8px;
                    font-weight: 500;
                    &:hover {
                        color: #9494F1;
                        .label {
                            color: #9494F1;
                        }
                    }
                }
            }
        }
    }

    .dx-upload-button {
        margin: auto;
    }
}
.dx-file {
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    width: 250px;
    &__img {
        width: 100%;
        svg {
            width: 100%;
            height: auto;
        }
    }
}
</style>