<template>
<div class="dx-card dx-setting-menu">
    <div class="menu-item--xl only-mobile d-none">
        <div class="label">
            <dx-icon icon="account" />
            {{ $t('topbar.setting.changeAccount') }}
        </div>
        <dx-icon icon="chevron-down" style="width: 9px;height: 9px;"/>
    </div>
    <hr class="divide only-mobile d-none" />
    <div class="menu-item--xl only-mobile">
        <div class="label">
            <dx-icon icon="setting" />
            {{ $t('topbar.setting.setting') }}
        </div>
    </div>
    <hr class="divide only-mobile" />
    <div class="menu-item">
        <div class="label">
            {{ $t('topbar.setting.theme') }}
        </div>
        <DxSelectBox class="arrow-sm" v-model="preferences.theme" :options="themes"/>
    </div>
    <div class="menu-item">
        <div class="label">
            {{ $t('topbar.setting.language') }}
        </div>
        <DxSelectBox class="arrow-sm" v-model="preferences.language" :options="languages" @update:modelValue="changeLanguage" />
    </div>
    <div class="menu-item d-none" @click="preferences.autoGenerateHistory = !preferences.autoGenerateHistory">
        <div class="label">
            {{ $t('topbar.setting.autoGenHistory') }}
        </div>
        <input type="checkbox"
            :off="$t('topbar.setting.autoGenHistoryOff')"
            :on="$t('topbar.setting.autoGenHistoryOn')"
            v-model="preferences.autoGenerateHistory"
            class="dx-checkbox" />
    </div>
    <div class="menu-item menu-item-link dangger d-none">{{ $t('topbar.setting.deleteHistory') }}</div>
    <hr class="divide only-mobile d-none"/>
    <div class="menu-item--xl only-mobile d-none">
        <div class="label">
            <dx-icon icon="question" />
            <div class="label">{{ $t('topbar.setting.help') }}</div>
        </div>
    </div>
</div>
</template>
<script setup lang="ts">
import { IOption } from '@/models/option';
import { useAccountStore } from '@/store/account';
import moment from 'moment';
import { computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import DxSelectBox from './DxSelectBox.vue';

const t = useI18n();
const themes: IOption[]= [
    {value: 'default', label: 'topbar.setting.themes.default'},
    // {value: 'dark', label: 'Dark'},
]

const languages: IOption[]= t.availableLocales.map(locale => {
    return  {value: locale, label: 'topbar.setting.languages.' + locale};
});
const useAccount = useAccountStore();
const preferences = computed(() => {
    return useAccount.preferences;
})

onMounted(() => {
    preferences.value.language = t.locale.value;
})

function changeLanguage(lang: string) {
    localStorage.setItem('dx-currentLang', lang);
    moment.locale(lang);
    t.locale.value = lang;
}
</script>
<style lang="scss">
.dx-setting-menu {
    padding: 20px;
    display: grid;
    gap: 22px;
    font-size: 12px;
    min-width: 270px;
    user-select: none;
    background-color: white !important;
    overflow: visible !important;
    >.menu-item {
        white-space: nowrap;
        height: 36px;
        padding: 0 10px;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        >*:first-child {
            margin-right: 20px;
        }
        // >:last-child {
        //     min-width: 50%;
        // }
        &.menu-item-link {
            color: $color3;
            cursor: pointer;
            &:hover {
                background-color: rgba($color3, 0.4) !important;
            }
        }
        .label {
            display: flex;
            align-items: center;
            svg { 
                margin-right: 1em;
            }
        }
        &--xl {
            @extend .menu-item;
            cursor: pointer;
            font-size: 15px;
            margin: -10px 0;
            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
            svg {
                width: 24px;
                height: 24px;
            }
        }
        // &.active {
        //     background-color: $color1 !important;
        //     color: white;
        //     &:hover {
        //         background-color: $color2 !important;
        //     }
        // }
        .dx-select {
            width: fit-content;
            text-align: right;
            background-color: transparent;
            .dx-select-input {
                background-color: transparent;
            }
        }
    }
    hr.divide {
        border-color: rgba($color1, 0.1);
        border-top-width: 1px;
        border-bottom-width: 0px;
        margin: 0 -20px -22px -20px;
    }
}
</style>