
<template>
    <div class="introduction-wrapper">
        <div class="dx-card introduction-card">
            <div class="dx-card-header">
                <img class="logo" src="@/assets/imgs/logo.svg"/>
                <div class="progress-dots">
                    <span class="dot" :class="{'active': index == 0}" @click="onNext(0)"></span>
                    <span class="dot" :class="{'active': index == 1}" @click="onNext(1)"></span>
                    <span class="dot" :class="{'active': index == 2}" @click="onNext(2)"></span>
                </div>
                <div>
                    <button class="dx-button dx-button-primary dx-button-sm" @click="onNext()">
                        {{ $t('introductionPage.next') }}
                        <dx-icon class="suffix" icon="chevron-right"/>
                    </button>
                </div>
            </div>
            <hr />
            <div class="dx-card-body" :style="`--count:${3}; --active-index:${index}`">
                <div class="content-wrapper">
                    <div class="content" id="intro1">
                        <div class="title">{{ $t('introductionPage.intro1.title') }}</div>
                        <strong>{{ $t('introductionPage.intro1.subtitle') }}</strong>
                        <div v-html="$t('introductionPage.intro1.content')"></div>
                        <img :src="require(`@/assets/imgs/introduction/img1_${$i18n.locale}.svg`)" />
                    </div>
                    <div class="content" id="intro2">
                        <div class="title">{{ $t('introductionPage.intro2.title') }}</div>
                        <div class="text" v-html="$t('introductionPage.intro2.content')"></div>
                        <img :src="require(`@/assets/imgs/introduction/img2_${$i18n.locale}.svg`)?? ''" />
                    </div>
                    <div class="content" id="intro3">
                        <div class="title">{{ $t('introductionPage.intro3.title') }}</div>
                        <strong> {{ $t('introductionPage.intro3.subtitle') }}</strong>
                        <div class="text" v-html="$t('introductionPage.intro3.content')"></div>
                        <img :src="require(`@/assets/imgs/introduction/img3_${$i18n.locale}.svg`)?? ''" />
                    </div>
                </div>
            </div>
            <router-link class="skip-tutorial" v-if="index==0"  :to="{name : 'main'}">{{ $t('introductionPage.skip') }}</router-link>
        </div>
    </div>
</template>
<script setup lang="ts">
import router from '@/router';
import { ref } from 'vue';

function onNext(_index?: number) {
    if(_index !== undefined) {
        index.value = _index;
        return;
    }
    if(index.value + 1 > 2) {
        router.push({name: 'main'});
        return;
    }
    index.value++;
}

const index = ref(0);
</script>
<style lang="scss" scoped>
@media only screen and (max-width: 768px) {
    .dx-button.dx-button-sm {
        height: 28px;
        font-size: 11px;
        border-radius: 4px;
        svg {
            height: 7px;
        }
    }
    img.logo {
        height: 24px;
    }

}
.introduction-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 15px;
    justify-content: center;
    align-items: center;
    .introduction-card {
        padding: 33px;
        width: 1064px;
        max-width: 100%;
        background-color: white;
        overflow: hidden;
        position: relative;
        .dx-card-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 37px;
        }
        .dx-card-body {
            margin-top: 1rem;
            overflow: hidden;
            .content-wrapper {
                display: flex;
                position: relative;
                width: calc(100% * var(--count));
                transform: translate(calc((-100% / var(--count)) * var(--active-index)));
            }
            .title {
                font-size: 40px;
                @media only screen and (max-width: 768px) {
                    font-size: 22px;
                }
                font-weight: 400;
            }
            .content {
                font-size: 20px;
                margin-bottom: 1em;
                @media only screen and (max-width: 768px) {
                    font-size: 13px;
                }
                text-align: left;
                width: calc(100% / var(--count));
                &#intro1 {
                    align-items: flex-start;
                    strong {
                        margin-top: 1em;
                        display: block;
                        font-weight: 600;
                    }
                    img {
                        margin-top: 2em;
                        width: 482px;
                        max-width: 100%;
                    }
                    @media only screen and (min-width: 769px) {
                        padding-left: 230px;
                        strong {
                            margin-top: 0.5em;
                        }
                    }
                }
                &#intro2 {
                    @media only screen and (min-width: 769px) {
                        padding: 0 230px;
                        .title {
                            width: 100%;
                            text-align: center;
                        } 
                        .text {
                            margin-top: 3em;
                        }
                        img {
                            margin-top: 3em;
                            max-width: 100%
                        }
                   
                    }
                    align-items: flex-start;
                    .text {
                        margin-top: 1em;
                    }
                    img {
                        margin-top: 1em;
                        max-width: 100%
                    }
                }
                &#intro3 {
                    @media only screen and (min-width: 769px) {
                        padding: 0 200px;
                        .title {
                            width: 100%;
                            text-align: center;
                        }
                        .text {
                            margin-top: 0;
                        }
                    }
                    strong {
                        font-weight: 600;
                        margin-top: 1em;
                    }
                    img {
                        margin-top: 1.5em;
                        max-width: 100%
                    }
                }
            }
        }
    }

    hr {
        border-color: rgba($color1, 0.5);
        border-top-width: 1px;
        border-bottom-width: 0px;
    }
    .progress-dots {
        display:  grid;
        grid-auto-flow: column;
        gap: 8px;
        .dot {
            width: 8px;
            height: 8px;
            margin: 0;
            cursor: pointer;
            background-color: rgba($color1, 0.25);
            border-radius: 50%;
            &.active {
                background-color: $color1;
            }
        }
    }
    .skip-tutorial {
        color: rgba($color1, 0.4);
        font-size: 11px;
        display: block;
        position: absolute;
    }
}
</style>