<template>
    <div class="page-container">
        <div class="page-container__header">
            <TopBar :class="'topbar'">
                <template v-slot:pageTitle>
                    <div class="dx-page-title">
                        <img src="@/assets/imgs/sidebar/history.svg"/>
                        {{ $t('historyPage.title') }}
                    </div>
                </template>
            </TopBar>
        </div>
        <div class="page-container__body" ref="containerRef">
            <div class="history-summary">{{ moment().format("ll") }} <span>{{ historyPageable.total }} {{ $t('historyPage.results') }}</span></div>
            <div class="history-results">
                <DxHistoryCard v-for="history in list" :data="history" :key="history.id" @onDeleted="removeHistory(history)"/>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import DxHistoryCard from '@/components/DxHistoryCard.vue';
import TopBar from '@/components/TopBar.vue';
import { useInfiniteScroll } from '@/composables/useInfiniteScroll';
import { useLoading } from '@/composables/useLoading';
import { useToast } from '@/composables/useToast';
import { IHistory } from '@/models/history';
import { IPageAble } from '@/models/page';
import historyService from '@/services/history.service';
import moment from 'moment';
import { computed, onMounted, onUnmounted, ref } from 'vue';
const ITEM_PER_PAGE = 24;

const containerRef = ref();

const historyPageable =  ref<IPageAble<IHistory>>({page: 0, itemPerPage: ITEM_PER_PAGE});
const list = computed(() => {
    return Array.from(new Set(historyPageable.value.list));
})

useInfiniteScroll(containerRef, async () => {
    if(((historyPageable.value.page + 1) * historyPageable.value.itemPerPage??0) >= (historyPageable.value.total ?? 0)) return;
    historyPageable.value.page++;
    const result = await historyService.list(historyPageable.value.page, ITEM_PER_PAGE);
    historyPageable.value.list?.push(...result.list??[]);
    historyPageable.value.total = result.total;
})
onMounted(() => {
    useLoading(true);
    historyService.list(0, ITEM_PER_PAGE)
    .then(data => historyPageable.value = data)
    .catch((err) => {
        useToast({message: err.message, type: 'error'});
    })
    .then(() => {
        useLoading(false);
    });
})

function removeHistory(history: IHistory) {
    const index = historyPageable.value?.list?.findIndex(item => item.id == history.id);
    if(index != undefined && index >= 0) {
        historyPageable.value?.list?.splice(index, 1);
        historyPageable.value.total = historyPageable.value.total! - 1;
    }
}

onUnmounted(() => {
    useLoading(false);
})

</script>
<style lang="scss" scoped>
.topbar {
    margin-left: auto;
}
.page-container {
    &__body {
        margin-top: 25px;
        padding: 25px 75px 0;
        margin-left: -25px;
        margin-right: -25px;
        overflow: auto;
        .history-summary {
            font-size: 12px;
            font-weight: 600;
            span {
                font-weight: 400;
            }
        }
        .history-results {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(min(400px, 100%), 1fr));
            gap: 1em;
            margin-top: 1em;
        }

    }

    @media only screen and (max-width: 768px) {
        height: 100%;
        &__body {
            padding: 15px 15px;
            margin: 0;
            margin-top: 0;
            flex: 1;
            min-height: 0;
            overflow: auto;
            .history-summary {
                display: none;
            }
            .history-results {
                margin-top: 0;
            }
        }
    }

}
</style>