<template>
    <div ref="container" class="dx-json-viewer" :class="{'is-preview': isPreview}">
        <pre>{{ data }}</pre>
    </div>
</template>
<script setup lang="ts">
import { defineProps, defineEmits, ref, watch } from 'vue';

const props = defineProps<{
    src: string;
    highlightText?: any;
    isPreview?: boolean;
}>()
const container = ref();
const data = ref();
watch(() => [props.src, props.highlightText],
() => {
    if(props.src) {
        load();
    }
}, {
    immediate: true
})
const emits = defineEmits(["onload", "onloadDone", "onloadFail"]);

function load() {
    fetch(props.src)
    .then((response) => {
       return response.json();
    })
    .then(json => {
        data.value = json;
        setTimeout(() => {
            renderHighlight();
            emits("onloadDone");
        }, 0);
    })
    .catch(err => {
        emits("onloadFail", err);
    })
}
function renderHighlight() {
    if(data.value && props.highlightText && container.value) {
        const regexStr = props.highlightText
                            .replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
                            .replace(/\s+/g, '\\s*')
                            .replace(/([{}[\]:,])/g, '$&\\s*')
                            .replace(/\\}/g, '\\s*\\}')
                            .replace(/\\]/g, '\\s*\\]')
                            .replace(/,/g, '\\s*,')
        const regex = new RegExp(regexStr, 'gi');
        const text = container.value.querySelector('pre').textContent;
        const {matches, matchesLength} = calculateRegExpMatch(regex, text);
        const result = convertMatches(matches, matchesLength, [text]);
        const divs = [container.value.querySelector('pre')];
        renderMatches(result, [text], divs)
        document.querySelector(".highlight.selected")?.scrollIntoView({block: 'center'});
    }
}
function convertMatches(matches: number[], matchesLength: number[], textContentItemsStr: any, ) {
    // Early exit if there is nothing to convert.
    if (!matches) {
      return [];
    }
    let i = 0,
      iIndex = 0;
    const end = textContentItemsStr.length - 1;
    const result = [];

    for (let m = 0, mm = matches.length; m < mm; m++) {
      // Calculate the start position.
      let matchIdx = matches[m];

      // Loop over the divIdxs.
      while (i !== end && matchIdx >= iIndex + textContentItemsStr[i].length) {
        iIndex += textContentItemsStr[i].length;
        i++;
      }

      if (i === textContentItemsStr.length) {
        console.error("Could not find a matching mapping");
      }

      const match: any = {
        begin: {
          divIdx: i,
          offset: matchIdx - iIndex,
        },
      };

      // Calculate the end position.
      matchIdx += matchesLength[m];

      // Somewhat the same array as above, but use > instead of >= to get
      // the end position right.
      while (i !== end && matchIdx > iIndex + textContentItemsStr[i].length) {
        iIndex += textContentItemsStr[i].length;
        i++;
      }

      match.end = {
        divIdx: i,
        offset: matchIdx - iIndex,
      };
      result.push(match);
    }
    return result;
  }

function renderMatches(matches: any[], textContentItemsStr: any, textDivs: any) {
  // Early exit if there is nothing to render.
  if (matches.length === 0) {
    return;
  }
  let prevEnd = null;
  const infinity = {
    divIdx: -1,
    offset: undefined,
  };

  function beginText(begin: any, className?: any) {
    const divIdx = begin.divIdx;
    textDivs[divIdx].textContent = "";
    return appendTextToDiv(divIdx, 0, begin.offset, className);
  }

  function appendTextToDiv(divIdx: any, fromOffset: any, toOffset: any, className?: any) {
    let div = textDivs[divIdx];
    if (div.nodeType === Node.TEXT_NODE) {
      const span = document.createElement("span");
      div.before(span);
      span.append(div);
      textDivs[divIdx] = span;
      div = span;
    }
    const content = textContentItemsStr[divIdx].substring(
      fromOffset,
      toOffset
    );
    const node = document.createTextNode(content);
    if (className) {
      const span = document.createElement("span");
      span.className = `${className} appended`;
      span.append(node);
      div.append(span);
      return className.includes("selected") ? span.offsetLeft : 0;
    }
    div.append(node);
    return 0;
  }

  let i0 = 0, i1 = matches.length;

  let lastDivIdx = -1;
  let lastOffset = -1;
  for (let i = i0; i < i1; i++) {
    const match = matches[i];
    const begin = match.begin;
    if (begin.divIdx === lastDivIdx && begin.offset === lastOffset) {
      // It's possible to be in this situation if we searched for a 'f' and we
      // have a ligature 'ff' in the text. The 'ff' has to be highlighted two
      // times.
      continue;
    }
    lastDivIdx = begin.divIdx;
    lastOffset = begin.offset;

    const end = match.end;
    let selectedLeft = 0;

    const isSelected = i == 0;
    const highlightSuffix = isSelected ? " selected" : "";

    // Match inside new div.
    if (!prevEnd || begin.divIdx !== prevEnd.divIdx) {
      // If there was a previous div, then add the text at the end.
      if (prevEnd !== null) {
        appendTextToDiv(prevEnd.divIdx, prevEnd.offset, infinity.offset);
      }
      // Clear the divs and set the content until the starting point.
      beginText(begin);
    } else {
      appendTextToDiv(prevEnd.divIdx, prevEnd.offset, begin.offset);
    }

    if (begin.divIdx === end.divIdx) {
      selectedLeft = appendTextToDiv(
        begin.divIdx,
        begin.offset,
        end.offset,
        "highlight" + highlightSuffix
      );
    } else {
      selectedLeft = appendTextToDiv(
        begin.divIdx,
        begin.offset,
        infinity.offset,
        "highlight begin" + highlightSuffix
      );
      for (let n0 = begin.divIdx + 1, n1 = end.divIdx; n0 < n1; n0++) {
        textDivs[n0].className = "highlight middle" + highlightSuffix;
      }
      beginText(end, "highlight end");
    }
    prevEnd = end;

  }

  if (prevEnd) {
    appendTextToDiv(prevEnd.divIdx, prevEnd.offset, infinity.offset);
  }
}

function calculateRegExpMatch(query: any, pageContent: any) {
    let matches = [], matchesLength = [];
    if (!query) {
      return {matches: [],
        matchesLength: []};
    }
    let match;
    while ((match = query.exec(pageContent)) !== null) {
      if (match.index === query.lastIndex) {
          query.lastIndex++;
      }

      if (match[0].length) {
        matches.push(match.index,);
        matchesLength.push(match[0].length);
      }
    }
    return {
      matches: matches,
      matchesLength: matchesLength
    }
}
</script>
<style lang="scss" scoped>
.dx-json-viewer {
    width: 100%;
    text-align: left;
    &.is-preview {
        font-size: 3px;
    }
    padding: 1em;
    pre {
        margin: 0;
        white-space: pre-wrap;
    }
    :deep(span.highlight) {
        background-color: #ffff0020;
    }
}
</style>