import { defineStore } from "pinia";
import { ref } from "vue";

export const useSearchStore = defineStore("search", () => {
    const fileSearchData = ref();
    const textTranslateData = ref();
    const fileTranslateData = ref();
    return {
        fileSearchData,
        textTranslateData,
        fileTranslateData
    }
})