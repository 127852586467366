<template>
    <div class="login-wrapper" @keyup.enter="onLogin">
        <div class="login-card dx-card">
            <div class="form">
                <div class="logo text-center">
                    <img src="@/assets/imgs/logo.svg" />
                </div>
                <div class="dx-error" v-if="error">⚠ {{ $t(error) }}</div>
                <input name="email" autocomplete="email" autocapitalize="none" class="dx-input" :placeholder="$t('loginPage.email')" v-model="email"/>
                <div class="dx-input password">
                    <input name="password" :placeholder="$t('loginPage.password')" v-model="password" :type="showPassword ? 'text' :'password'"/>
                    <inline-svg
                        class="suffix cursor-pointer" @click="showPassword = !showPassword"
                        :src="showPassword ? require('@/assets/imgs/icons/show-password.svg') : require('@/assets/imgs/icons/hide-password.svg')" />
                </div>
                <button class="dx-button dx-button-primary" @click="onLogin">{{ $t('loginPage.continue')}}</button>
                <!-- <div class="or">
                    <hr>
                    <span>{{ 'OR' }}</span>
                    <hr>
                </div>
                <button class="dx-button dx-button-secondary">
                    <inline-svg class="prefix" :src="require('@/assets/imgs/icons/google.svg')"/>
                    Continue
                </button> -->
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import router from '@/router';
import userService from '@/services/user.service';
import { useAccountStore } from '@/store/account';
import { onMounted, ref } from 'vue';
const showPassword = ref(false);
const email = ref("");
const password = ref("");
const useAccount = useAccountStore();

const error = ref();

onMounted(() => {
  userService.login()
  .then(user => {
    if(user) {
      useAccount.account = user;
      router.push({name: "main"});
    } 
  })
  .catch(() => {
    //
  })
})

function onLogin() {
    error.value = null;
    userService.login(email.value, password.value)
    .then((user)=>{
        if(user) {
            useAccount.account = user;
            router.push({name: 'introduction'});
        } else {
            error.value = 'loginPage.incorrectUsrPwd';
        }
    })
    .catch((err)=>{
        error.value = err.message;
    })
}
</script>
<style lang="scss" scoped>

.login-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 15px;
    justify-content: center;
    align-items: center;
    .login-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 67px;
        @media only screen and (max-width: 768px) {
            padding: 25px 40px;
        }
        max-width: 100%;
        width: 475px;
        .logo {
            margin: auto;
            width: 154px;
            max-width: 100%;
            height: 46px;
            border-bottom: 1px solid rgba($color1, 0.1);
            img {
                height: 24px;
            }
        }
        .form {
            width: 357px;
            max-width: 100%;
            display: grid;
            grid-template-columns: 1fr;
            gap: 27px;
        }
        .or {
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 70%;
            color: rgba($color1, 0.5);
            font-size: 14px;
            span {
                padding: 0 2rem;
            }
            hr {
                flex: 1;
                border-color: rgba($color1, 0.1);
                border-top-width: 1px;
                border-bottom-width: 0px;
            }
        }
        .dx-error {
            color: $color3;
            font-size: 12px;
            margin: -15px 0;
        }
    }
}
.password {
    svg {
        color: #9E9EAA;
        transition: color;
        &:hover {
            color: #D1D1D1;
        }
    }
}
</style>