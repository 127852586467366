import { http, useMock } from "@/commons/http-common";
import { getCookie } from "@/commons/utils";
import { createSearchStreaming, createTranslateStreaming } from "@/mock";
import { File, IFile } from "@/models/file";
interface FetchResult<T> {
  promise: Promise<T>;
  controller: AbortController;
}

class LlmService {
    search(content: string, useDoc?: boolean): Promise<ReadableStream> {
      if(useMock) {
        return createSearchStreaming();
      } else {
        return fetch(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_PATH}/llm/search`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCookie("csrftoken")
          },
          body: JSON.stringify({
            content: content,
            use_rag: useDoc ?? false
          })
        }).then(res => {
          if(res.body && res.status == 200) {
            return res.body;
          } else {
            throw new Error('Fail to fetch result');
          }
        }).catch(err => {
          throw err;
        });
      }
    }

    fileTranslate(documentId: number, languages: Array<string>): FetchResult<string> {
      const controller = new AbortController();
      const signal = controller.signal;
      const data:any = {
        document_id: documentId,
        languages: languages,
      };
      const promise = fetch(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_PATH}/llm/translate`, {
        method: "POST",
        signal,
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": getCookie("csrftoken")
        },
        body: JSON.stringify(data)
      })
      .then(response => {
        if (!response.ok) {
            throw new Error(`${response.status} ${response.statusText}`);
        }
        return response.json();
      })
      .then((json) => {
        if(json.status == 0) {
          return json.tracker;
        } else {
          throw new Error(json.meesage);
        }
      })
      .catch(err => {
        throw err;
      })

      return {
        controller: controller,
        promise: promise
      }
    }

    translate({content, documentId, languages}: {content?: any, documentId?: any, languages:any}) : FetchResult<ReadableStream> {
      const controller = new AbortController();
      const signal = controller.signal;
      let promise: Promise<ReadableStream>;

      if(useMock && !Number.isInteger(documentId)) {
        promise = createTranslateStreaming();
      } else {
        const data:any = {
          content: content,
          languages: languages,
        };
        promise = fetch(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_PATH}/llm/translate`, {
          method: "POST",
          signal,
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCookie("csrftoken")
          },
          body: JSON.stringify(data)
        }).then(res => {
          if(res.body && res.status == 200) {
            return res.body;
          } else {
            throw new Error('Fail to fetch result');
          }
        }).catch(err => {
          throw err;
        })
      }
        

      return {
        controller: controller,
        promise: promise
      }
    }
}
export default new LlmService();
