import mock from "@/mock";
import axios from "axios";
import { getCookie } from "./utils";

const HEADERS = {
  form: {
     "Content-Type": "multipart/form-data",
  },
  default: { 
    "Content-type": "application/json" ,
  },
};
interface APIResponse {
  status: number;
  message?: string;
}

export const useMock = process.env.NODE_ENV === "development";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL + process.env.VUE_APP_API_PATH,
  headers: HEADERS.default,
});
apiClient.interceptors.response.use(
  (response) => {
    if (useMock) {
      console.log("Response mock", response);
    }
    const error = response.data as APIResponse;
    if (error?.status == 1) {
      return Promise.reject({
        message: `${error.message}`,
      });
    }
    return response;
  },
  (errorResponse) => {
    if (useMock) {
      console.log("Response mock", errorResponse);
    }
    return Promise.reject(errorResponse);
  }
);
apiClient.interceptors.request.use((config: any) => {
  if(useMock) {
    console.log("Request mock", config.url, config);
  }
  config.headers.set("X-CSRFToken", getCookie("csrftoken"));
  return config;
});

if (useMock) {
  mock.mocked(apiClient);
}

export { HEADERS, apiClient as http };

