import { getFileFormat } from "@/commons/utils";
import { File, IFile } from "@/models/file";
import { ref, watchEffect } from "vue";

interface IUploadParams {
    dropArea?: any;
    uploadButton?: any;
    multiple?: boolean;
    allowedTypes?: string[]; 
    callback?: any;
}
export function useUpload(params: IUploadParams) {
    const uploadData = ref<IFile | IFile[]>();
    const {dropArea, uploadButton, multiple, callback, allowedTypes} = params;
    watchEffect(() => {
        if(dropArea?.value) {
            dropArea.value.addEventListener("dragenter", function () {
                dropArea.value.classList.add("dx-dropbox-dragenter");
            });

            dropArea.value.addEventListener("dragleave", function (event: DragEvent) {
                if (!dropArea.value.contains(event.relatedTarget)) {
                    dropArea.value.classList.remove('dx-dropbox-dragenter');
                }
            });
            dropArea.value.addEventListener("dragover", function (event: DragEvent) {
                event.preventDefault();
            });
            dropArea.value.addEventListener("drop", function (event: DragEvent) {
                event.preventDefault();
                dropArea.value.classList.remove("dx-dropbox-dragenter");
                const uploadFiles = event.dataTransfer?.files;
                if (!uploadFiles) return;
                getFileData(uploadFiles);
            });
        }
        if(uploadButton?.value) {
            uploadButton.value.addEventListener("click", function() {
                const input = document.createElement('input');
                input.type = 'file';
                if(allowedTypes) {
                    input.accept = allowedTypes.toString();
                }
                input.style.display = 'none';
                input.multiple = !!multiple;
                input.addEventListener('change', (event: any) => {
                    const uploadFiles = event.target.files || event.dataTransfer.files;
                    getFileData(uploadFiles);
                });
                input.click();
            })
        }

        function getFileData(uploadFiles: FileList) {
            let validFiles = Array.from(uploadFiles);
            if(allowedTypes) {
                validFiles = validFiles.filter(file => {
                    const fileType = file.name.toLowerCase();
                    return (allowedTypes.some((type: string) => fileType.endsWith(type)))
                })
            }
            if (multiple) {
                uploadData.value = validFiles.map((item: any) => {
                    return new File({
                        fileName: item.name,
                        file: item,
                        type: getFileFormat(item),
                        lastModified: new Date().toISOString(),
                        size: item.size
                    });
                });
                if(callback) callback(uploadData.value);
                
            } else {
                if(validFiles.length > 0) {
                    const file = validFiles[0];
                    uploadData.value = new File({
                        fileName: file.name,
                        file: file,
                        type: getFileFormat(file),
                        lastModified: new Date().toISOString(),
                        size: file.size
                    });
                    if(callback) callback(uploadData.value);
                }
            }
        }
    });

    return uploadData;
}