<template>
    <div v-if="data" class="dx-file-search-result" :class="{ 'search': data.mode == Mode.Search, 'answer': data.mode == Mode.Answer }">
        <div class="dx-file-search-result__body" ref="containerRef">
            <TransitionGroup>
                <div v-if="data.mode == Mode.Answer" class="dx-file-search-result__section">
                    <div class="dx-file-search-result__title">{{ data.title }}</div>
                    <div class="dx-file-search-result__headline" v-if="data.resultText">
                        <dx-icon icon="AI-bot"></dx-icon>
                        AI response:
                    </div>
                    <VueMarkdown class="markdown answer" v-if="data.resultText" :source="data.resultText"></VueMarkdown>
                    <!-- <div class="dx-file-search-result__headline" v-if="writeDone">
                        Also check:
                    </div> -->
                </div>
                <div v-for="(chunks, index) in chunkGroups" :key="chunks[0].id" class="dx-file-search-result__section">
                    <div v-if="index == 0 && data.mode == Mode.Search" class="dx-file-search-result__title">{{ data.title }}</div>
                    <div class="dx-file-search-result__file-name">
                        <dx-icon icon="file-search"/>
                        {{chunks[0].file?.fileName}}
                    </div>
                    <DxChunk v-for="(chunk, chunkIndex) in chunks" :key="chunkIndex" 
                            :selected="selectedChunk == chunk"
                            :chunk="chunk"
                            :index="chunkIndex"
                            @onFileClick="viewFile(chunk)"/>
                </div>
            </TransitionGroup>
            <!-- <Transition>
                <div class="dx-file-search-result__actions-button" v-if="(data?.resultText && writeDone) || !data?.resultText && (data?.chunks?.length??0) > 0">
                    <div class="action-button" @click="regeneration">
                        <inline-svg :src="require('@/assets/imgs/icons/refresh.svg')"/>
                        {{ $t('fileSearchPage.result.regenerate') }}
                    </div>
                    <div class="action-button" @click="copy">
                        <inline-svg :src="require('@/assets/imgs/icons/copy2.svg')"/>
                        {{ $t('fileSearchPage.result.copy') }}
                    </div>
                </div>
            </Transition> -->
        </div>
    </div>
</template>
<script setup lang="ts">
import { copyToClipboad } from '@/commons/utils';
import { useToast } from '@/composables/useToast';
import { IChunk } from '@/models/chunk';
import { Mode } from '@/models/search-result';
import { computed, defineEmits, defineProps, ref } from 'vue';
import VueMarkdown from 'vue-markdown-render';
import DxChunk from './DxChunk.vue';
const props = defineProps<{
    data?: {mode?: Mode, title: string, resultText?: string, chunks?: IChunk[]};
    writeDone: boolean;
    selectedChunk?: IChunk;
}>()
const emits = defineEmits(["regeneration", "onViewFile"]);
const containerRef = ref();

const chunkGroups = computed((): Array<Array<IChunk>> => {
    if(!props.data?.chunks) {
        return [];
    }
    const groupById =  props.data.chunks.reduce((acc: any, curChunk: IChunk) => {
        const id = curChunk.file?.id;
        if (!acc[id]) {
            acc[id] = [];
        }
        acc[id].push(curChunk);
        return acc;
        }, {});
    return Object.values(groupById);
})

// watch(() => props.data?.resultText,
// () => {
//     setTimeout(() => {
//         containerRef.value?.scrollTo(0, containerRef.value?.scrollHeight);
//     }, 0);
// })

function regeneration() {
    emits("regeneration");
}
function copy() {
    copyToClipboad(props?.data?.resultText??'').then(() => {
        useToast({type: 'success', message: "Copied!", deleyTime: 1})
    });
}
function viewFile(chunk: IChunk) {
    emits("onViewFile", chunk);
}
</script>
<style lang="scss" scoped>

.underline-bold {
    text-decoration: underline;
    font-weight: 700;
}
.dx-file-search-result {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: 0 !important;
    &__section {
        &:only-child {
            height: fit-content;
            min-height: 100%;
        }
        padding: 40px 0;
        > * {
            max-width: calc(var(--max-main-width) * 0.83);
            margin-left: calc(var(--max-main-width) * 0.07);
        }
        @media only screen and (max-width: 768px), (max-aspect-ratio: 1/1) {
            padding: 25px 25px 25px;
            > * {
                max-width: unset;
                margin: 0;
            }
        }
        &:nth-child(2n + 1) {
            background-color: white;
        }
    }
    &.search .dx-file-search-result__section {
        &:nth-child(2n) {
            background-color: white;
        }
        &:nth-child(2n + 1) {
            background-color: initial;
        }
    }
    &__header {
        font-size: 14px;
        margin: 0 5px;
        padding: 0 25px;
        height: 70px;
        line-height: 70px;
        display: flex;
    }
    &__headline {
        @extend .underline-bold;
        position: relative;
        svg {
            position: absolute;
            width: 20px;
            height: auto;
            top: 50%;
            left: 0;
            margin-right: 1em;
            transform: translateX(-100%) translateY(-50%);
        }
    }
    &__file-name {
        display: flex;
        align-items: center;
        color: $color4;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 25px;
        svg {
            color: $color1;
            width: 20px;
            margin-right: 0.5em;
        }
    }
    &__body {
        overflow: auto;
        flex: 1;
        min-height: 0;
    }
    &__title {
        font-size: 20px;
        font-weight: 700;
        color: $color1;
        margin-bottom: 35px;
        text-decoration: underline;
    }
    &__hypersearch-buttons {
        display: grid;
        gap: 24px;
        margin-top: 24px;
    }
    
    &__actions-button {
        display: flex;
        align-items: center;
        width: fit-content;
        margin-left: auto;
        margin-top: 50px;
        border-radius: 10px;
        height: 30px;
        border: 1px solid rgba($color1, 0.15);
    }

    .action-button {
        display: flex;
        justify-self: center;
        align-items: center;
        height: 100%;
        padding: 5px;
        font-size: 12px;
        cursor: pointer;
        svg {
            margin-right: 0.5em;
        }
        &:hover {
            color: $color4;
        }
        &:first-child {
            border-right: 1px solid rgba($color1, 0.15);
        }
    }
    .hypersearch-button {
        background-color: rgba(white, 0.6);
        border-radius: 10px;
        border: 1px solid rgba($color4, 0.2);
        padding: 15px 30px;
        cursor: pointer;
        &--title {
            color: $color4;
            font-size: 18px;
        }
        &--text {
            color: $color1;
            font-size: 12px;
        }
    }
    .answer {
        font-family: inherit;
        color: $color1;
        font-size: 18px;
        // margin-top: -0.5em;
        margin-bottom: 1.5em;
    }
}

.actions {
    display: flex;
    align-items: center;
    font-size: 12px;
    width: 100%;
    .action {
        &:not(:first-child) {
            margin-left: 28px;
        }
        display: flex;
        align-items: center;
        height: 36px;
        cursor: pointer;
        border-radius: 8px;
        overflow: hidden;
        @media only screen and (max-width: 768px), (max-aspect-ratio: 1/1) {
            height: 32px;
        }
        .label {
            flex: 1;
            overflow: hidden;
            margin-left: 0.5em;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        &:not(.new-action):hover {
            color: rgba($color1, 0.6);
        }
        &.new-action {
            margin-left: auto;
        }
    }
}
</style>