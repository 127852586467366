<template>
<div class="dx-card history-card">
    <div class="dx-card-header">
        <div class="history-info">
            <div class="history-type">
                <dx-icon v-if="(data as History).methodIcon" class="mr-0_5em" :icon="(data as History).methodIcon"/>
                {{ $t((data as History).methodName) }}
            </div>
            <div>
                {{ moment(data.modified).format("YYYY/M/DD HH:mm")}}
                <DxDropdown class="action">
                    <template v-slot:default="{ open }">
                        <dx-icon class="ml-0_5em" icon="three-dots-vertical" @click="open"/>
                    </template>
                    <template v-slot:dropdown="{ close }">
                        <div class="dx-dropdown-menu">
                            <div class="dx-dropdown-menu__item--color-danger" @click="deleteHistory(close);">
                                <dx-icon icon="delete"></dx-icon>
                                <div class="label">
                                    {{ $t('fileSearchPage.files.delete') }}
                                </div>
                            </div>
                        </div>
                    </template>
                </DxDropdown>
            </div>
        </div>
        <div class="history-title">"{{data.prompt}}"</div>
    </div>
    <div class="dx-card-body history-card-body">{{ data.answer }}</div>
</div>
</template>
<script setup lang="ts">
import { useLoading } from '@/composables/useLoading';
import { useToast } from '@/composables/useToast';
import { History, IHistory } from '@/models/history';
import historyService from '@/services/history.service';
import moment from 'moment';
import { defineEmits, defineProps } from 'vue';
import DxDropdown from './DxDropdown.vue';

const props = defineProps<{
    data: IHistory
}>();
const emits = defineEmits(['onDeleted'])
function deleteHistory(callback: any) {
    useLoading(true);
    historyService.delete(props.data.id)
    .then(() => {
        emits('onDeleted');
        if(callback) {
            callback(); 
        } 
    })
    .catch((err) => {
        useToast({type: 'error', message: err.message});
    })
    .finally(() => {
        useLoading(false);
    })
}
</script>
<style lang="scss" scoped>
.history-card {
    background-color: rgba(white, 0.8);
    padding: 16px;
    .history-info {
        width: 100%;
        display: flex;
        font-size: 11px;
        color: rgba($color1, 0.5);
        justify-content: space-between;
        .history-type {
            font-weight: 600;
            color: rgba($color1, 0.5);
        }
        > div {
            display: flex;
            align-items: center;
            .action {
                display: flex;
                align-items: center;
                cursor: pointer;
            }
            
        }
    }
    .history-title {
        padding: 1em 0 0.5em 0;
        border-bottom: 1px solid rgba($color1, 0.1);
    }

    .history-card-body {
        margin-top: 1em;
        color: $color4;
        font-size: 12px;
        line-height: 18px;
        height: 54px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
}
</style>