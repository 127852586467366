import { http } from "@/commons/http-common";
import { IAccount } from "@/models/account";

class UserService {
    login(email?: string, password?: string): Promise<IAccount | null> {
        const data = {email: email ?? '',password: password ?? '',}
        return http.post("/user/login", data, {
            headers: {"Content-type": 'application/x-www-form-urlencoded'},
          }).then(res => {
            const data = res.data;
            if(data.user) {
                return { name : `${data.user.first} ${data.user.last}`};
            }
            return null;
          })
          .catch((error: any) => {
              throw error;
          });
    }
    logout() {
        return http.get("/user/logout")
    }
}
export default new UserService();