import { TOAST_DELEY_SECCOND } from "@/commons/const";
import { IToast } from "@/models/toast";
import { useToastStore } from "@/store/toast";

export function useToast(toast: IToast) {
    const toastStore = useToastStore();
    toast.key = '' + new Date().getTime();
    toastStore.push(toast)
    setTimeout(() => {
        toastStore.remove(toast);
    }, (toast.deleyTime ?? TOAST_DELEY_SECCOND) * 1000);
}