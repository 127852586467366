class AiliaRealtimeService {
    private url;
    private socket:any;
    private subscriptions:any;
    private retryTimer: any;
    private isRetryEnabled;
    private retryCount;
    private retryDelays;
    constructor() {
      this.url = new URL('/ws/v1/realtime', process.env.VUE_APP_API_URL);
      this.url.protocol = 'wss:';
      this.socket = null;
      this.subscriptions = [];
      this.isRetryEnabled = false;
      this.retryTimer = null;
      this.retryCount = 0;
      this.retryDelays = [2500, 5000, 10000, 30000];
    }
  
    subscribe(subscription: any) {
      return this.subscriptions.push(subscription) - 1;
    }
  
    unsubscribe(index: number) {
      delete this.subscriptions[index];
    }
  
    connect() {
      if (this.socket !== null) {
        return;
      }
      this.socket = new WebSocket(this.url);
      this.socket.onopen = (event: Event) => this.onOpen(event);
      this.socket.onclose = (event: Event) => this.onClose(event);
      this.socket.onmessage = (event: Event) => this.onMessage(event);
      this.socket.onerror = (event: Event) => this.onError(event);
      this.isRetryEnabled = true;
    }
  
    disconnect() {
      this.isRetryEnabled = false;
      this.socket?.close();
    }
  
    retry() {
      if (!this.isRetryEnabled || this.retryTimer !== null) {
        return;
      }
      this.retryTimer = setTimeout(() => {
        this.retryTimer = null;
        this.retryCount++;
        this.connect();
      }, this.retryDelays[Math.min(this.retryCount, this.retryDelays.length - 1)]);
    }
  
    clearRetry() {
      if (this.retryTimer === null) {
        return;
      }
      clearTimeout(this.retryTimer);
      this.retryTimer = null;
      this.retryCount = 0;
    }
  
    onOpen(event: Event) {
      this.clearRetry();
      this.subscriptions.forEach((subscription: any) => {
        subscription.onOpen?.();
      });
    }
  
    onClose(event: Event) {
      this.socket = null;
      this.clearRetry();
      this.subscriptions.forEach((subscription: any) => {
        subscription.onClose?.();
      });
      this.retry();
    }
  
    onMessage(event: any) {
      const data = JSON.parse(event.data);
      this.subscriptions.forEach((subscription: any) => {
        subscription.onMessage?.(data);
      });
    }
  
    onError(event: any) {
      this.subscriptions.forEach((subscription: any) => {
        subscription.onError?.();
      });
      this.retry();
    }
  }
  export { AiliaRealtimeService };
  export default new AiliaRealtimeService();