<template>
    <div class="dx-select" ref="element" :class="{'open': open}">
        <div class="dx-select-input" @click="open=!open">
            <dx-icon class="prefix-icon" v-if="selectedOption?.icon" :icon="selectedOption.icon"/>
            <span class="text">{{ $t(selectedOption?.label || emptyText || '')}}</span>
            <dx-icon class="toggle-icon" :style="{transform: `rotate(${open ? -180 : 0}deg)`}" icon="chevron-down" />
        </div>
        <div class="dx-select-options">
            <div class="option" :key="option.value"
                v-for="option in options"
                @click="select(option)"
                :class="{'selected': modelValue == option.value }">
                <dx-icon v-if="option.icon" :icon="option.icon"/>
                <span class="label">{{ $t(option.label) }}</span>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { IOption } from '@/models/option';
import { computed, defineEmits, defineProps, onMounted, onUnmounted, ref, watch } from 'vue';

const props = defineProps<{
    options: Array<IOption>;
    modelValue?: any;
    emptyText?: string;
    required?: boolean;
}>();
const emits = defineEmits([
    "update:modelValue", 
    "onSelect" // Manual change
]);
watch(() => props.modelValue,
() => {
    if(!props.modelValue) {
        select(props.options[0]);
    }
})
const selectedOption = computed(() => {
    return props.options.find(item => item.value == props.modelValue);
})

const element = ref<HTMLElement | null>();
const open = ref(false);

function select(option: IOption) {
    open.value = false;
    if(option.value == props.modelValue) return;
    emits("update:modelValue", option.value);
    emits("onSelect");

}

onMounted(() => {
    if(props.required && !props.modelValue) {
        emits("update:modelValue", props.options[0].value); 
    }
    document.addEventListener("click", handleClickOutside);
});
onUnmounted(() => {
  document.removeEventListener("click", handleClickOutside);
});

function handleClickOutside(event: Event) {
  if (element.value && !element.value.contains(event.target as HTMLElement)) {
    open.value = false;
  }
}
</script>
<stype lang="scss" scoped>
.dx-select {
    position: relative;
    height: 39px;
    max-height: 100%;
    cursor: pointer;
    .dx-select-input {
        display: flex;
        align-items: center;
        height: 100%;
        background-color: rgba(white, 0.6);
        border-radius: 4px;
        border: 1px solid rgba($color1, 0.15);
        padding: 0 20px;
        font-size-adjust: 14px;
        transition: 0.25s;
        .prefix-icon {
            height: 1.5em;
            width: auto;
            margin-right: 0.5em;
        }
        .text {
            flex: 1;
            min-width: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .toggle-icon {
            height: 1em;
            margin-left: 0.5em;
            transition: 0.25s;
        }
    }
    .dx-select-options {
        position: absolute;
        display: none;
        text-align: left;
        top: 100%;
        left: 0;
        width: 100%;
        border-radius: 0 0 4px 4px;
        background: white;
        overflow: hidden;
        color: $color1;
        border: 1px solid rgba($color1, 0.15);
        // box-shadow: 6px 3px 35.1px 0px rgba($color1, $alpha: 0.15);
        max-height: min(500px, 100vh);
        overflow: auto;
        .option {
            display: flex;
            align-items: center;
            padding: 1em;
            &.selected, &:hover {
                background-color:  rgba($color1, 0.05);;
            }
            svg {
                height: 1.5em;
                width: auto;
                margin-right: 0.5em;
            }
            .label {
                flex: 1;
                min-width: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
    &.open {
        .dx-select-options {
            display: block;
            z-index: 3;
            border-top: 0px;
        }
        .dx-select-input {
            border-radius: 4px 4px 0 0;
        }
    }
    &.arrow-sm {
        svg {
            width: 10px;
        }
    }
}
</stype>