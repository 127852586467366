import { IFile } from "@/models/file";

export const debounce = <F extends (...args: any[]) => void>(func: F, delay: number) => {
    let timeoutId: ReturnType<typeof setTimeout> | null;

    return function (this: ThisParameterType<F>, ...args: Parameters<F>) {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        timeoutId = setTimeout(() => {
            func.apply(this, args);
            timeoutId = null;
        }, delay);
    };
};

export const copyToClipboad = (text: string) => {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    textarea.setAttribute('readonly', '');
    textarea.style.position = 'absolute';
    textarea.style.left = '-9999px';

    document.body.appendChild(textarea);

    textarea.select();

    let success = false;
    try {
        const result = document.execCommand('copy');
        success = result ? true : false;
    } catch (err) {
        console.error('Error copying text to clipboard:', err);
    } finally {
        document.body.removeChild(textarea);
    }


    if (!success && navigator.clipboard) {
        return navigator.clipboard.writeText(text).then(() => true).catch(() => false);
    }

    return Promise.resolve(success);
}

export const downloadTxtFile = (text: string, filename: string) => {
    const blob = new Blob([text], { type: 'text/plain' });

    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    try {
        a.style.display = 'none';
        a.href = url;
        a.download = filename;
    
        document.body.appendChild(a);
        a.click();
    
    } finally {
        document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
};

export function getCookie(cname:string) {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function formatBytes(bytes: number, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export function getImageUrl(file: IFile): string {
  if(file.url) {
    return file.url;
  } 
  if(file.file) {
    const _url = URL.createObjectURL(file.file)
    file.url = _url;
    return _url;
  }
  return "";
}

export function getFileFormat(file: File) {
  if(file.type.startsWith('image/')) {
    return "image";
  }
  if(file.type.startsWith('video/')) {
    return "video";
  }
  if(file.type.startsWith('audio/')) {
    return "sound";
  }
  const extension = file.name?.split('.')?.pop()?.toLowerCase();
  const wordExtensions = ['docx'];
  const excelExtensions = ['xls', 'xlsx', 'csv'];
  const powerpointExtensions = ['ppt', 'pptx'];
  if(extension && wordExtensions.includes(extension)) {
    return "docx";
  }
  if(extension && excelExtensions.includes(extension)) {
    return "xlsx";
  } 
  if(extension && powerpointExtensions.includes(extension)) {
    return "pptx";
  } 
  if(extension == 'pdf') {
    return "pdf";
  }
  if(extension == "txt") {
    return "txt";
  }
  return extension;
}