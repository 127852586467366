<template>
    <div class="dx-lang-options">
        <div class="language">
            <DxSelectBox :options="languages1" :required="true" v-model="lang1" @on-select="change"/>
        </div>
        <div class="swap" @click="swap" :class="{'disabled': lang1 == 'auto'}">
            <inline-svg :src="require('@/assets/imgs/icons/swap.svg')" />
        </div>
        <div class="language">
            <DxSelectBox :options="languages2" :required="true" v-model="lang2" @on-select="change"/>
        </div>
    </div>
</template>
<script setup lang="ts">
import { LANGUAGES } from '@/commons/const';
import { computed, defineEmits, defineProps, ref, watch } from 'vue';
import DxSelectBox from './DxSelectBox.vue';

const props = defineProps<{
    modelValue?: Array<string>;
}>();

const lang1 = ref();
const lang2 = ref();
const emits = defineEmits(["update:modelValue", "onSelect"]);

watch(() => props.modelValue, 
() => {
    if(props.modelValue) {
        if(props.modelValue[0] && props.modelValue[0] != lang1.value) {
            lang1.value = props.modelValue[0];
        } 
        if(props.modelValue[1] && props.modelValue[1] != lang2.value) {
            lang2.value = props.modelValue[1];
        }
    }
})
watch(() => [lang1.value, lang2.value], 
() => {
    if(lang1.value || lang2.value) {
        emits("update:modelValue", [lang1.value, lang2.value]);
    }
})


const LANGUAGES_1 = ['auto', ...LANGUAGES].map(item => {
    return { value: item, label: 'language.' + item};
});
const LANGUAGES_2 = [...LANGUAGES].map(item => {
    return { value: item, label: 'language.' + item};
});

const languages1 = computed(() => {
    return LANGUAGES_1;
})
const languages2 = computed(() => {
    return LANGUAGES_2;
})
function swap() {
    if(lang1.value == 'auto') return;
    const temp = lang1.value;
    lang1.value = lang2.value;
    lang2.value = temp;
    emits("update:modelValue", [lang1.value, lang2.value]);
    emits("onSelect");
}
function change() {
    if(lang1.value == lang2.value) {
        if(lang1.value == props.modelValue?.at(0)) {
            lang1.value = props.modelValue?.at(1);
        } else if(lang1.value == props.modelValue?.at(1) && props.modelValue?.at(0) != 'auto') {
            lang2.value = props.modelValue?.at(0);
        }
    }
    emits("update:modelValue", [lang1.value, lang2.value]);
    emits("onSelect");
}
</script>
<style lang="scss" scoped>
.dx-lang-options {
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    .dx-select {
        font-size: 12px;
        min-width: 130px;
        .dx-select-input {
            overflow: hidden;
        }
    }
    .language {
        flex: 1;
        display: flex;
        &:first-child {
            justify-content: flex-end;
        }
    }
    .swap {
        margin: 0 10px;
        border-radius: 4px;
        height: 39px;
        width: 39px;
        max-height: 100%;
        background-color: rgba(white, 0.6);
        border: 1px solid rgba($color1, 0.15);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &.disabled {
            opacity: 0.5;
            cursor: default;
            pointer-events: none;
        }
    }
}
</style>