import { AxiosInstance } from "axios";
import MockAdapter from "axios-mock-adapter";
import histories from "./history.json";
import { getFileFormat } from "@/commons/utils";
import { streamDataMock } from "./fileSearch";
import { streamDataMock as translateStreamDataMock } from "./translation";
import fileList from "./fileList.json";
import chunkList from "./chunks.json"

const delayTime = 1000;
let docs: any[] = [];
let storange: any = localStorage.getItem("mock");
function getMockData() {
  try {
    return JSON.parse(localStorage.getItem("mock")?? '{}');
  } catch(ex) {
    return {};
  }
}
function setMocData(data: any) {
  localStorage.setItem("mock", JSON.stringify(data))
}
class Mock {
  mocked(axiosClient: AxiosInstance) {
    if(!storange) {
      localStorage.setItem("mock", '{}');
      storange = {};
    }
    const mock = new MockAdapter(axiosClient, {
      delayResponse: delayTime,
      onNoMatch: "passthrough",
    });

    mock.onPost("/user/login").reply((config: any) => {
      const urlParams = new URLSearchParams(config.data);

      // return  [200, {status: 0, user: {first: "David", last: "Cochard"}}];
      const mockData = getMockData();
      if(urlParams.get('email') === '' && urlParams.get('password') === '') {
        const currentUser = mockData.currentUser;
        if(currentUser) {
          return [200 , {status:0 , user: currentUser}];
        } else {
          return [200, { status: 1, message: "Not logged in" }];
        }
      }
      if(urlParams.get('email')?.toLocaleLowerCase() == 'david@axinc.jp' && urlParams.get('password') == 'password') {
        const currentUser = {first: "David", last: "Cochard"};
        mockData['currentUser'] = currentUser;
        setMocData(mockData);
        return  [200, {status: 0, user: {first: "David", last: "Cochard"}}];
      } else {
        return [200, {status: 1, message: 'Password incorrect',}];
      }

    });
    mock.onGet("/user/logout").reply((config: any) => {
      const mockData = getMockData();
      delete mockData['currentUser'];
      setMocData(mockData);
      return [200, {status: 0}]
    }) ;
    mock.onPost("/doc/upload").reply((config: any) => {
      const formData = config.data;
      const file = formData.get("file");
      const id = docs.length + 1
      docs.push({
        id: id,
        name: file.name,
        format: getFileFormat(file),
        modified: new Date().toISOString(),
        size: file.size,
        file: file,
        url: URL.createObjectURL(file), //"/api/v1/doc/download/" + id,
        owner: { first: "David", last: "Cochard"}
      })
      return [200, { id: id,status: 0 }];
    });
    mock.onPost("/doc/list").reply((config: any) => {
      const urlParams = new URLSearchParams(config.data);
      const page = Number(urlParams.get("page"));
      const size = Number(urlParams.get("size"));
      return [200, fileList];
      return [200, {documents: docs.slice(page * size , page * size + size), 
        "page": page,
        "pages": docs.length / size,
        "size": size,
        "total":  docs.length}];
    });
    mock.onPost("/doc/delete").reply((config: any) => {
      const urlParams = new URLSearchParams(config.data);
      const id = Number(urlParams.get("id"));
      docs = docs.filter(item => item.id != id);
      return [200];
    });
    
    mock.onPost("/llm/translate").reply((config: any) => {
      const urlParams = new URLSearchParams(config.data);
      const id = Number(urlParams.get("id"));
      docs = docs.filter(item => item.id != id);
      return [200, {target: {
        id: id,
        name: 'file-sample.json',
        format: 'json',
        modified: new Date().toISOString(),
        size: 777,
        url: "/sample/file-sample.json",
        owner: { first: "David", last: "Cochard"}
      }}];
    });
    mock.onPost("/history/list").reply((config: any) => {
      return [200, histories];
    });
    mock.onPost("/history/delete").reply((config: any) => {
      const urlParams = new URLSearchParams(config.data);
      const id = Number(urlParams.get("id"));
      return [200];
    });
    mock.onPost("/rag/search").reply((config: any) => {
      const urlParams = new URLSearchParams(config.data);
      return [200, chunkList];
    });
  }
}

function createReadableStream(_text? : string) {
  let count = 0;
  const contents = _text? 
                    (_text.match(/\w+\s*/g) ?? [])
                        .map((t, index, array) => {
                          return JSON.stringify({
                            content: t
                          }) +  (index < array.length - 1 ? "\n" : "");
                      })
                    : streamDataMock.split("\n").map((line, index, array) => index < array.length - 1 ? line + "\n" : line);
  const length = contents.length;
  let isCancelled = false;
  return new ReadableStream({
      start(controller) {
        function pushData() {
            if (isCancelled) {
              return; 
            }
            const encoder = new TextEncoder();
            const text = contents?.shift();
            
            const encodedUint8Array = encoder.encode(text);
            const chunk = encodedUint8Array;

            count++;

            controller.enqueue(chunk);
            if (count < length) {
                setTimeout(pushData, 0);
            } else {
                controller.close();
            }
        }

        pushData();
      },

      cancel() {
          isCancelled = true;
      }
  });
}
function createSearchStreamingMock(): Promise<ReadableStream> {
  return new Promise((resolve) =>{
    setTimeout(() => {
      const stream = createReadableStream();
      resolve(stream);
    }, delayTime);
  });
}
function createTranslateStreamingMock(): Promise<ReadableStream> {
  return new Promise((resolve) =>{
    setTimeout(() => {
      const sentences = [
        `["1Excepteur sint "]`,
        `["2Excepteur sint occaecat \n cupidatat"]`,
        `["3Excepteur sint occaecat \n cupidatat non proident sunt in culpa qui"]`,
        `["4Excepteur sint occaecat \n cupidatat non proident, sunt in culpa qui officia deserunt"]`,
        `["5Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.haha \n\n cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."]`,
      ];
    
      const randomIndex = Math.floor(Math.random() * sentences.length);
      const text = sentences[randomIndex];
      console.log(text);
      const stream = createReadableStream(text);
      resolve(stream);
    }, delayTime);
  });
}
export default new Mock();
export { createSearchStreamingMock as createSearchStreaming, createTranslateStreamingMock as createTranslateStreaming };
