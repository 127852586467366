<template>
    <div class="chunk" :class="{'selected': selected}">
        <div class="chunk__header">
            <div class="chunk__header__index">{{ (index ?? 0) + 1 }}</div>
            <div class="chunk__header__file" v-if="chunk.file" @click="viewFile">
                <inline-svg v-if="chunk.file?.type" :src="require(`@/assets/imgs/icons/file-type-sm-${chunk.file?.type}.svg`)"/>
                <span>{{ chunk.file?.fileName }}</span>
            </div>
        </div>
        <div class="chunk__content">
            <div class="underline-bold">extract:</div>
            <div class="content" v-if="chunk.text">{{ (chunk.text as string)?.trim() }}</div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { IChunk } from '@/models/chunk';
import { defineEmits, defineProps } from 'vue';

defineProps<{
    chunk: IChunk;
    selected?: boolean;
    index?: number;
}>()

const emits = defineEmits(["onFileClick"])

function viewFile() {
    emits("onFileClick");
}

</script>
<style lang="scss" scoped>
.underline-bold {
    text-decoration: underline;
    font-weight: 700;
}
.chunk {
    font-family: inherit;
    font-size: 13px;
    margin-bottom: 20px;
    &.selected {
        .chunk__header__file {
            background-color: rgba($color1, 0.15);
        }
    }
    >svg {
        position: absolute;
        top: 17px;
        left: 17px;
        width: 12px;
        height: 12px;
    }
    &__header {
        margin-bottom: 1em;
        display: flex;
        align-items: center;
        &__index {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: $color1;
            color: white;
            text-align: center;
            margin-right: 1em;
        }
        &__file {
            margin-right: 10px;
            border-radius: 8px;
            background-color: white;
            border: 1px solid rgba($color1, 0.15);
            display: flex;
            padding: 0px 10px;
            height: 30px;
            align-items: center;
            cursor: pointer;
            white-space: nowrap;
            overflow: hidden;
            width: fit-content;
            max-width: 100%;
            svg {
                flex-shrink: 0;
                margin-right: 10px;
                height: 17px;
                width: auto;
            }
            span {
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
    &__content {
        padding: 30px 40px;
        max-height: 15em;
        border-radius: 10px;
        border: 1px solid rgba($color: $color1, $alpha: 0.15);
        @media only screen and (max-width: 768px) {
            padding: 15px 20px;
        }
        .content {
            word-break: break-word;
            font-family: inherit;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            margin: 0;
        }
    }
}
</style>