<template>
  <div class="dx-spinner" >
    <svg viewBox="0 0 36 36" width="50" height="50">
        <path class="circle"
              fill="none"
              stroke-width="3"
              stroke-linecap="round"
              stroke="currentcolor"
              d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831">
        </path>
    </svg>
</div>
</template>
<style lang="scss" scoped>
svg {
  width: 50px;
  height: 50px;
  color: rgba($color1, 0.1);
  animation-timing-function: ease;
  animation: progress-animation infinite 1.5s;
  :deep(path) {
    animation-timing-function: inherit;
    animation: path-progress-animation infinite 1.5s;
        }
}
@keyframes progress-animation {
    0% {
        transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
}
@keyframes path-progress-animation {
    0% {
      stroke-dasharray: 28, 5.33333, 28, 5.33333, 28, 5.3333;
    }
    50% {
      stroke-dasharray: 3, 33.333, 3, 33.333, 3, 33.333;
    }
    100% {
      stroke-dasharray: 28, 5.33333, 28, 5.33333, 28, 5.3333;
    }
}
</style>